$primary: #45CCFF; /* MAIN COLOR */
$secondary: #6ebe44; /* SECONDARY COLOR */
$third: #0074a0;
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Miriam+Libre|Slabo+27px');

h1,h2,h3 {
	font-family: 'Miriam Libre', sans-serif;
}
p,a {
	font-family: 'Slabo 27px', serif;
}
.flash {
	display:none;
}


/** NAV START **/
nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;
	
	.navbar-nav {

	    > li > a {
			text-align: center;
			height: 90px;
			display: flex;
			align-items: center;
			color: white;
			font-size: 1.3em;


			@media (max-width: 767px) {
			    height: 30px;
			    display: inline-block;
			    padding: 5px;
			    margin: 0;
			    color: black;
			}

		    &:hover {
		    	background: $primary;
		    	color: lighten($primary, 50%);
		    }

			&:focus, &:active {
			  background: transparent;
			  color: white;
			  outline: 0;
			  @media (max-width: 767px) {
			  	color: black;
			  }
			}
		}
	}
}


.navbar-right {
	margin-top: 0px;
}


.navbar-toggle {
	margin-top: 15px;
}


/** LOGIN FORM **/
@mixin btn1 {
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}


@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}


.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	input#username, input#password {
		@media (max-width: 1024px) {
			font-size: 16px;
		}
	}
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}



.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}


input#username {
	margin-bottom: 20px;
}


/** END LOGIN FORM **/
footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;
		white-space: nowrap;

		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}
}


input#username, input#password {
	width: 100%;
}


@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}


select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    } 
}

.banner {
	background: url(../img/banner.jpg) no-repeat center;
	background-size: cover;
	padding: 10em 0em;
	background-position: 50% 50%;
	@media (max-width: 991px) {
		padding: 7em 0;
	}
	@media (max-width: 767px) {
		padding: 5em 0;
	}
}
.infoBox {
	padding: 30px;
	p, h1 {
		color: white;
	}
	p {
		font-size: 1.4em;
	}
	h1 {
		@media (max-width: 991px) {
			font-size: 1.7em;
		}
	}
}
.bg1 {
	background: url(../img/txtBox1.jpg) no-repeat;
	background-size: cover;
	background-clip: content-box;
	margin-top: 15px;
	background-position: 50% 30%;
	.lg {
		background: linear-gradient(
           rgba(0,0,0,0.6),
           rgba(0,0,0,0.6)
			);
		height: 300px;
		@media (max-width: 991px) {
			height: auto;
		}
		width: 100%;
	}
}
.bg2 {
	background: url(../img/txtBox2.jpg) no-repeat;
	background-size: cover;
	background-clip: content-box;
	margin-top: 15px;
	background-position: 50% 50%;
	.lg {
		background: linear-gradient(
           rgba(0,0,0,0.6),
           rgba(0,0,0,0.6)
			);
		height: 300px;
		@media (max-width: 991px) {
			height: auto;
		}
		width: 100%;
	}
}


@mixin minimalBtn {
	border-top: 1px solid white;
	border-bottom: 1px solid white;
	padding: 10px;
	margin: 8px 0;
	color: white;
	font-size: 1.3em;
	display: inline-block;
	&:hover, &:active, &:focus {
		color: white;
		text-decoration: none;
	}
}
a.btn {
	@include minimalBtn
}
a.btn2 {
	@include minimalBtn
	color: black;
	border-top: 1px solid black;
	border-bottom: 1px solid black;
	&:hover, &:active, &:focus {
		color: black;
		text-decoration: none;
	}
}
.banner {
	.txtBox {
		padding: 40px 15px;
		color: white;
		background: rgba(0,0,0,0.2);
		@media (max-width: 991px) {
			margin-top: 60px;
		}
		h1 {
			font-size: 3em;
			@media (max-width: 767px) {
				font-size: 2em;
			}
		}
		p {
			font-size: 1.2em;
		}
		a {
			font-size: 1.3em;
		}
	}
}
.padTopBtm {
	padding: 20px 15px;
}
.formTxt {
	p {
		font-size: 1.5em;
	}
}
.navbar-default {
	background: rgba(33,120,149,0.7);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
	transition: all 0.5s ease-in-out;
	@media (max-width: 767px) {
		background: white;
	}
}
nav.navbar.solidNavBg {
	background: white;
	.navbar-nav > li > a {
		color: black;
	}
}
.title {
	margin-bottom: 20px;
}
.btmBanner {
	background: url(../img/btmBanner.jpg) no-repeat;
	background-size: cover;
	padding: 100px 0;

	@media (max-width: 767px) {
		padding: 50px 0;
	}
	h1 {
		color: white;
		font-size: 4em;
		@media (max-width: 991px) {
			font-size: 2.7em;
		}
		@media (max-width: 767px) {
			font-size: 2.5em;
		}
	}
}
.heart {
	max-width: 100px;
	width: 100%;
}

.midSectionBg {
	background: $primary;
	padding-bottom: 40px;
	h1,p {
		color: white;
	}
	p {
		font-size: 1.5em;
		@media (max-width: 991px) {
			font-size: 1.3em;
		}
	}
	h1 {
		@media (max-width: 991px) {
			font-size: 2em;
		}
	}
	.col-xs-6.steps {
		@media (max-width: 600px) {
			width: 100%;
		}
		.btmImg {
		@media (max-width: 991px) {
			max-width: 300px;
			width: 100%;
			display: block;
			margin: 0 auto;
		}
	}
	}
}

.top-pad {
	margin-top: 130px;
}

.termsPrivacyNav {
	background: $third;
}